import { getErrorMessages } from './error_utils'

export const getOrderErrorMessages = (errors) => {
  const values = {
    duplicatedNumber: 'Número do pedido duplicado',
    default: 'Não foi possível salvar o pedido',
  }
  return getErrorMessages(values, errors)
}

export const translatePaymentConditions = (paymentConditions, language, id) => {
  if (paymentConditions != null || paymentConditions != undefined) {
    const description = JSON.parse(paymentConditions)
    let paymentTranslation = description.filter(
      (desc) => desc.language === language.toLowerCase()
    )
    if (paymentTranslation.length > 0) {
      return {
        id: id,
        label: paymentTranslation[0].label,
      }
    }
  }
}
