import NumberFormater from '../../helpers/number_formater'
import { getOrderErrorMessages } from '../../helpers/order_utils'
// import jsPDF from 'jspdf'

export default {
  data: () => ({
    id: null,
    distributor: null,
    payment: null,
    isPrinting: false,
    preview: {
      distributor: '',
      paymentMethod: '',
      parcialBilling: false,
      shippingCompany: '',
      cnpj: ''
    }
  }),
  computed: {
    order() {
      return this.$store.state.order.view
    },
    products() {
      return this.$store.state.order.inserter.rows
    },
    loading() {
      return !this.$store.state.system.loading.done
    }
  },
  created() {
    // this.products = (this.$store.state.viewCtl == 'uploader-list') ?
    // this.$store.state.uploader.rows :
    // this.$store.state.inserter.rows\
    this.language = localStorage.getItem('currentLang') || 'pt';
    this.getData()
    this.updateProducts()
  },
  mounted() {
    // this.$store.state.order.preventClear = false
  },
  watch: {
    // 'order.distributor_id': function( value ) {
    //     this.getDistributor( value )
    // },
    // 'order.payment_method_id': function( value ) {
    //     this.getPayment( value )
    // },
    // '$route.name': function( value ) {
    // 	if( value == 'order-create' ){
    // 		this.$store.dispatch( 'order/clear', 'view' )
    // 	}
    // }
  },
  methods: {
    updateProducts() {
      if (this.$store.state.order.uploader.rows.length) {
        this.$store.state.order.inserter.rows = this.$store.state.order.uploader.rows.map(item => {
          item.data.rawValue = item.data.value
          item.data.rawTotalValue = item.data.value * item.data.quantity
          return item
        })
      }
    },
    async getData() {
      // get distributor
      var distributor = await this.$store.dispatch(
        'distributor/getClean',
        this.order.distributor_id
      )
      this.preview.distributor = distributor.body.name

      // get payment method
      await this.$store.dispatch('paymentMethods/get', this.order.payment_method_id)
      var payment = this.$store.state.paymentMethods.view
      this.preview.paymentMethod = payment.description[this.language]

      // set faturamento
      if (
        this.order.parcial_billing
      ) {
        this.preview.parcialBilling = true
      } else {
        this.preview.parcialBilling = false
      }

      // get shipping company
      var shippingCompany = await this.$store.dispatch('order/shippingCompany', this.order.shipping_code)
      this.preview.shippingCompany = shippingCompany.body.name

      // get CNPJ
      var CNPJ = await this.$store.dispatch('distributor/getCNPJ', this.order.cnpj_id)
      this.preview.cnpj = CNPJ.cnpj

    },
    format(value) {
      if (value) {
        return NumberFormater.money(value, { decimalLength: 2 })
      } else {
        return null
      }
    },
    back() {
      this.$router.push({
        name: 'order-create'
      })
    },
    async save() {
      this.$store.dispatch('system/loading', 'data')

      try {
        const result = await this.$store.dispatch('order/create')

        if (result.status) {
          this.$store.state.order.preventClear = false

          this.$store.commit('snackbar/show', {
            content: 'Pedido salvo com sucesso!',
            type: "success",
          })

          this.$store.dispatch('order/clear', 'view')

          this.$router.push({
            name: 'order-list'
          })
        } else {
          const message = getOrderErrorMessages(result.body.errors || [])
          this.$store.commit('snackbar/show', {
            content: message,
            type: "error",
          })
        }
      } catch (error) {
        this.$store.commit('snackbar/show', {
          content: error.message,
          type: "error",
        })
      } finally {
        this.$store.dispatch('system/loading', 'done')
      }
    },
  },
  destroyed() {
    // this.$store.dispatch( 'order/clear', 'view' )
  }
}
